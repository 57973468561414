import React from 'react';
import { PropTypes } from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Menu, MenuItem, ListItemIcon, ListItemText, Button } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

import * as authSelectors from 'app/redux/auth/selectors';
import * as cartActions from 'app/redux/cart/actions';
import * as cartSelectors from 'app/redux/cart/selectors';
import * as stateSelectors from 'app/redux/state/selectors';
import { dialogActions } from 'app/redux/ui/actions';
import { ButtonSpinner } from 'common/statusIndicators';
import * as API from 'service/api';
import { getRoutePath } from 'service/navigation';
import { getErrorMessage } from 'service/utility';
import { formatName } from 'service/utility/stringFormatters';

import CartMenuItem from './CartMenuItem';


class CartSelector extends React.Component {
  constructor(props) {
    super(props);

    this.initialState = {
      firstTimeLoad: true,
      loadedCartCount: 0,
      loadedPages: 0,
      hasMoreCarts: true,
      carts: [],
    };

    this.state = {
      anchorEl: null,
      ...this.initialState,
    };
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  shopCheck = () => {
    const { history, checkout } = this.props;

    if (history.location.pathname !== getRoutePath('SHOP') && !checkout) {
      history.push(getRoutePath('SHOP'));
    }
  };

  handleMenuOpen = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    this.doFirstTimeLoad();
  };

  doFirstTimeLoad = () => {
    this.setState(this.initialState, this.loadMoreCarts);
  };

  loadMoreCarts = async () => {
    const { currentLocation } = this.props;
    const { hasMoreCarts, loadedPages } = this.state;

    if (!hasMoreCarts) return;

    try {
      const { data: { data, pagination } } = await API.searchCarts([
        'source=INSTORE',
        'status=ACTIVE',
        `locationId=${currentLocation.id}`,
        'sort=updatedAt',
        'order=DESC',
        'pageSize=15',
        `page=${loadedPages + 1}`,
      ].join('&'));

      if (this._isMounted) {
        this.setState((prevState) => ({
          firstTimeLoad: false,
          loadedCartCount: prevState.loadedCartCount + data.length,
          loadedPages: prevState.loadedPages + 1,
          hasMoreCarts: prevState.loadedCartCount + data.length < pagination.rowCount,
          carts: prevState.carts.concat(data),
        }));
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);

      console.log('API.searchCarts error: ');
      console.log(errorMessage);

      toast.error(errorMessage);
    }
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
  };

  handleCartSelect = (cart) => {
    const { currentStaff, activeCartId, setActiveCart, assignStaffToCart, confirmAction } = this.props;

    this.handleMenuClose();

    if (cart.staffId === currentStaff.id) {
      // It's already my cart;; focus on it
      this.shopCheck();
      if (activeCartId !== cart.UUID) {
        // focus on it
        setActiveCart(cart);
      }
    } else if (!cart.staffId) {
      // Cart is unassigned; take over
      this.shopCheck();
      assignStaffToCart(currentStaff.id, cart.UUID);
    } else {
      // Somebody else's cart; confirm takeover
      confirmAction(
        () => {
          this.shopCheck();
          assignStaffToCart(currentStaff.id, cart.UUID);
        },
        {
          title: 'Assign Cart to Self?',
          message: `It looks like this customer's cart is in use by ${formatName(cart.staff)}. Do you want to take ownership?`,
        },
      );
    }
  };

  handleNewCart = () => {
    this.handleMenuClose();
    this.props.createNewCart();
  };


  render() {
    const { activeCartId, activeCartCustomer, checkout, timeZone } = this.props;
    const { anchorEl, carts, firstTimeLoad, loadedCartCount, hasMoreCarts } = this.state;

    return (
      <div className="cart-selector">
        <Button
          onClick={this.handleMenuOpen}
          color="inherit"
        >
          <ArrowDropDownIcon className="arrow-drop-down" />
          {activeCartCustomer ? formatName(activeCartCustomer) : 'Guest Cart'}
        </Button>
        <Menu
          classes={{ paper: 'cart-selector-menu' }}
          PaperProps={{
            id: 'cart-selector-menu-paper',
          }}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={this.handleMenuClose}
          disableAutoFocusItem
        >
          {!checkout && (
            <MenuItem onClick={this.handleNewCart}>
              <ListItemIcon>
                <AddIcon />
              </ListItemIcon>
              <ListItemText primary="New Cart" />
            </MenuItem>
          )}
          {firstTimeLoad ? (
            <MenuItem>
              <ButtonSpinner />
            </MenuItem>
          ) : (
            <InfiniteScroll
              scrollableTarget="cart-selector-menu-paper"
              scrollThreshold={0.7}
              dataLength={loadedCartCount}
              next={this.loadMoreCarts}
              hasMore={hasMoreCarts}
              loader={
                <MenuItem>
                  <ButtonSpinner />
                </MenuItem>
              }
            >
              {carts.map((cart) => (
                <CartMenuItem
                  key={cart.UUID}
                  cart={cart}
                  isSelected={activeCartId === cart.UUID}
                  onCartSelect={this.handleCartSelect}
                  timeZone={timeZone}
                />
              ))}
            </InfiniteScroll>
          )}
        </Menu>
      </div>
    );
  }
}

CartSelector.propTypes = {
  activeCartCustomer: PropTypes.object,
  activeCartId: PropTypes.string,
  assignStaffToCart: PropTypes.func.isRequired,
  checkout: PropTypes.bool,
  confirmAction: PropTypes.func.isRequired,
  createNewCart: PropTypes.func.isRequired,
  currentLocation: PropTypes.object.isRequired,
  currentStaff: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setActiveCart: PropTypes.func.isRequired,
  timeZone: PropTypes.string.isRequired,
};


const mapStateToProps = (state) => ({
  activeCartCustomer: cartSelectors.activeCartCustomerSelector(state),
  activeCartId: cartSelectors.activeCartIdSelector(state),
  currentLocation: stateSelectors.currentLocation(state),
  currentStaff: authSelectors.currentStaff(state),
});

const mapDispatchToProps = (dispatch) => ({
  assignStaffToCart: (staffId, cartId) => dispatch(cartActions.assignStaffToCart(staffId, cartId)),
  confirmAction: (callback, config) => dispatch(dialogActions.confirmAction(callback, config)),
  createNewCart: () => dispatch(cartActions.createNewCart()),
  setActiveCart: (cart) => dispatch(cartActions.setActiveCart(cart)),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CartSelector));
