import React from 'react';

import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import BookmarksOutlinedIcon from '@material-ui/icons/BookmarksOutlined';
import BusinessIcon from '@material-ui/icons/Business';
// import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import EmailOutlined from '@material-ui/icons/EmailOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import FilterBAndWIcon from '@material-ui/icons/FilterBAndW';
import FolderIcon from '@material-ui/icons/Folder';
import HowToRegOutlinedIcon from '@material-ui/icons/HowToRegOutlined';
import InfoIcon from '@material-ui/icons/Info';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ListAltIcon from '@material-ui/icons/ListAlt';
import NoteIcon from '@material-ui/icons/Note';
import NotesIcon from '@material-ui/icons/Notes';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PaymentIcon from '@material-ui/icons/Payment';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import PersonIcon from '@material-ui/icons/Person';
import PhoneOutlinedIcon from '@material-ui/icons/PhoneOutlined';
import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
// import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import SettingsIcon from '@material-ui/icons/Settings';
import SortIcon from '@material-ui/icons/Sort';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import AuthRoute from 'app/AuthRoute';
import Cart from 'app/cart/Cart';
import CartTotals from 'app/cart/CartTotals';
import MinimalLayout from 'app/layouts/minimal';
import { PrivateRoute } from 'app/navigation';
import Barcode from 'assets/Barcode';
import { SwitchWithRoutes, SwitchWithRoutesAndLink2 } from 'common/navigation';
import { BARCODE_MIN_LENGHT } from 'config/barcodes';
import {
  CANCELLABLE_ITEM_TYPES, CHECKIN_AFTER_PURCHASE_ITEM_TYPES, PROMO_CODE_ITEM_TYPES,
} from 'config/itemTypes';
import { REPORT_HEADERS } from 'config/revenueCategoryReports';
import { SKELETON_ANIMATION, TOAST_DURATION } from 'config/ui';
import { PERMISSIONS, SECTIONS } from 'constants/permissions';
import { injectConfig } from 'service/navigation';

import AppConfig from '../app';


/* eslint-disable camelcase */
const Login = React.lazy(
  () => import('base/auth/Login')
);
const ResetPassword = React.lazy(
  () => import('base/auth/ResetPassword')
);
const AuthApp = React.lazy(
  () => import('../app/AuthApp')
);
const StripeCallback = React.lazy(
  () => import('base/StripeCallback')
);
const Reports = React.lazy(
  () => import('base/reports')
);
const GoBD = React.lazy(
  () => import('base/reports/GoBD')
);
const Profile = React.lazy(
  () => import('base/profile')
);
const Profile_Info = React.lazy(
  () => import('base/profile/views/Info')
);
const Profile_Settings = React.lazy(
  () => import('base/profile/views/Settings')
);
const Profile_Defaults = React.lazy(
  () => import('base/profile/views/Defaults')
);
const Checkout = React.lazy(
  () => import('base/checkout')
);
const Settings = React.lazy(
  () => import('base/settings')
);
const Settings_General = React.lazy(
  () => import('base/settings/views/General')
);
const Settings_Default = React.lazy(
  () => import('base/settings/views/Defaults')
);
const Settings_Portal = React.lazy(
  () => import('base/settings/views/Portal')
);
const Settings_Checkins = React.lazy(
  () => import('base/settings/views/Checkins')
);
const Settings_EmailSender = React.lazy(
  () => import('base/settings/views/EmailSender')
);
const Settings_EmailReceipts = React.lazy(
  () => import('base/settings/views/EmailReceipts')
);
const Settings_EmailMarketing = React.lazy(
  () => import('base/settings/views/EmailMarketing')
);
const Settings_Points = React.lazy(
  () => import('base/settings/views/Points')
);
const Settings_Sounds = React.lazy(
  () => import('base/settings/views/Sounds')
);
const Main2 = React.lazy(
  () => import('../app/Main2')
);
const ActivityNew = React.lazy(
  () => import('base/admin/Activities/ActivityNew')
);
const ActivityDetail = React.lazy(
  () => import('base/admin/Activities/ActivityDetail')
);
const ActivityList = React.lazy(
  () => import('base/admin/Activities/ActivityList')
);
const CalendarMerge = React.lazy(
  () => import('base/admin/Calendars/CalendarMerge')
);
const CalendarNew = React.lazy(
  () => import('base/admin/Calendars/CalendarNew')
);
const CalendarDetail = React.lazy(
  () => import('base/admin/Calendars/CalendarDetail')
);
const CalendarList = React.lazy(
  () => import('base/admin/Calendars/CalendarList')
);
const CertificationNew = React.lazy(
  () => import('base/admin/Certifications/CertificationNew')
);
const CertificationDetail = React.lazy(
  () => import('base/admin/Certifications/CertificationDetail')
);
const CertificationList = React.lazy(
  () => import('base/admin/Certifications/CertificationList')
);
const DocumentTemplateNewEdit = React.lazy(
  () => import('base/admin/DocumentTemplates/DocumentTemplateNewEdit')
);
const DocumentTemplatesList = React.lazy(
  () => import('base/admin/DocumentTemplates/DocumentTemplatesList')
);
const ReminderTemplateNew = React.lazy(
  () => import('base/admin/ReminderTemplates/ReminderTemplateNew')
);
const ReminderTemplateDetail = React.lazy(
  () => import('base/admin/ReminderTemplates/ReminderTemplateDetail')
);
const ReminderTemplatesList = React.lazy(
  () => import('base/admin/ReminderTemplates/ReminderTemplatesList')
);
const GiftCardTypeNew = React.lazy(
  () => import('base/admin/GiftCardTypes/GiftCardTypeNew')
);
const GiftCardTypeDetail = React.lazy(
  () => import('base/admin/GiftCardTypes/GiftCardTypeDetail')
);
const GiftCardTypeList = React.lazy(
  () => import('base/admin/GiftCardTypes/GiftCardTypeList')
);
const InventoryLogNew = React.lazy(
  () => import('base/admin/InventoryLogs/InventoryLogNew')
);
const InventoryLogDetail = React.lazy(
  () => import('base/admin/InventoryLogs/InventoryLogDetail')
);
const InventoryLogList = React.lazy(
  () => import('base/admin/InventoryLogs/InventoryLogList')
);
const LocationNew = React.lazy(
  () => import('base/admin/Locations/LocationNew')
);
const LocationDetail = React.lazy(
  () => import('base/admin/Locations/LocationDetail')
);
const LocationDetail_Info = React.lazy(
  () => import('base/admin/Locations/LocationDetail/views/Info')
);
const LocationDetail_DeviceNew = React.lazy(
  () => import('base/admin/Locations/LocationDetail/views/DeviceNew')
);
const LocationDetail_DeviceDetail = React.lazy(
  () => import('base/admin/Locations/LocationDetail/views/DeviceDetail')
);
const LocationDetail_DeviceList = React.lazy(
  () => import('base/admin/Locations/LocationDetail/views/DeviceList')
);
const LocationDetail_StaffNew = React.lazy(
  () => import('base/admin/Locations/LocationDetail/views/StaffNew')
);
const LocationDetail_StaffList = React.lazy(
  () => import('base/admin/Locations/LocationDetail/views/StaffList')
);
const LocationList = React.lazy(
  () => import('base/admin/Locations/LocationList')
);
const MembershipManagement = React.lazy(
  () => import('base/admin/MembershipManagement')
);
const MembershipResumeList = React.lazy(
  () => import('base/admin/MembershipResumeList')
);
const MembershipHistory = React.lazy(
  () => import('base/admin/MembershipHistory')
);
const MembershipTypeNew = React.lazy(
  () => import('base/admin/MembershipTypes/MembershipTypeNew')
);
const MembershipTypeDetail = React.lazy(
  () => import('base/admin/MembershipTypes/MembershipTypeDetail')
);
const MembershipTypeList = React.lazy(
  () => import('base/admin/MembershipTypes/MembershipTypeList')
);
const PassTypeNew = React.lazy(
  () => import('base/admin/PassTypes/PassTypeNew')
);
const PassTypeDetail = React.lazy(
  () => import('base/admin/PassTypes/PassTypeDetail')
);
const PassTypeList = React.lazy(
  () => import('base/admin/PassTypes/PassTypeList')
);
const PricingTierNew = React.lazy(
  () => import('base/admin/PricingTiers/PricingTierNew')
);
const PricingTierDetail = React.lazy(
  () => import('base/admin/PricingTiers/PricingTierDetail')
);
const PricingTierList = React.lazy(
  () => import('base/admin/PricingTiers/PricingTierList')
);
const ProductNew = React.lazy(
  () => import('base/admin/Products/ProductNew')
);
const ProductDetail = React.lazy(
  () => import('base/admin/Products/ProductDetail')
);
const ProductDetail_Info = React.lazy(
  () => import('base/admin/Products/ProductDetail/views/Info')
);
const ProductDetail_InventoryAdjustment = React.lazy(
  () => import('base/admin/Products/ProductDetail/views/InventoryAdjustment')
);
const ProductDetail_VariantsSetup = React.lazy(
  () => import('base/admin/Products/ProductDetail/views/VariantsSetup')
);
const ProductDetail_VariantsEdit = React.lazy(
  () => import('base/admin/Products/ProductDetail/views/VariantsEdit')
);
const ProductDetail_Variants = React.lazy(
  () => import('base/admin/Products/ProductDetail/views/Variants')
);
const ProductDetail_Inventory = React.lazy(
  () => import('base/admin/Products/ProductDetail/views/Inventory')
);
const ProductList = React.lazy(
  () => import('base/admin/Products/ProductList')
);
const PromoCodeNew = React.lazy(
  () => import('base/admin/PromoCodes/PromoCodeNew')
);
const PromoCodeDetail = React.lazy(
  () => import('base/admin/PromoCodes/PromoCodeDetail')
);
const PromoCodeDetail_Info = React.lazy(
  () => import('base/admin/PromoCodes/PromoCodeDetail/views/Info')
);
const PromoCodeDetail_ApplicableItems = React.lazy(
  () => import('base/admin/PromoCodes/PromoCodeDetail/views/ApplicableItems')
);
const PromoCodeList = React.lazy(
  () => import('base/admin/PromoCodes/PromoCodeList')
);
const RevenueCategoryNew = React.lazy(
  () => import('base/admin/RevenueCategories/RevenueCategoryNew')
);
const RevenueCategoryDetail = React.lazy(
  () => import('base/admin/RevenueCategories/RevenueCategoryDetail')
);
const RevenueCategoryList = React.lazy(
  () => import('base/admin/RevenueCategories/RevenueCategoryList')
);
const StaffNew = React.lazy(
  () => import('base/admin/Staff/StaffNew')
);
const StaffDetail = React.lazy(
  () => import('base/admin/Staff/StaffDetail')
);
const StaffDetail_Certifications = React.lazy(
  () => import('base/admin/Staff/StaffDetail/views/Certifications')
);
const StaffDetail_Profile = React.lazy(
  () => import('base/admin/Staff/StaffDetail/views/Profile')
);
const StaffList = React.lazy(
  () => import('base/admin/Staff/StaffList')
);
const StaffRoleEdit = React.lazy(
  () => import('base/admin/StaffRoles/StaffRoleEdit')
);
const StaffRoleList = React.lazy(
  () => import('base/admin/StaffRoles/StaffRoleList')
);
const TicketTypeNew = React.lazy(
  () => import('base/admin/TicketTypes/TicketTypeNew')
);
const TicketTypeDetail = React.lazy(
  () => import('base/admin/TicketTypes/TicketTypeDetail')
);
const TicketTypeList = React.lazy(
  () => import('base/admin/TicketTypes/TicketTypeList')
);
const VariantTypeNew = React.lazy(
  () => import('base/admin/VariantTypes/VariantTypeNew')
);
const VariantTypeDetail = React.lazy(
  () => import('base/admin/VariantTypes/VariantTypeDetail')
);
const VariantTypeDetail_VariantOptions = React.lazy(
  () => import('base/admin/VariantTypes/VariantTypeDetail/views/VariantOptions')
);
const VariantTypeDetail_Info = React.lazy(
  () => import('base/admin/VariantTypes/VariantTypeDetail/views/Info')
);
const VariantTypeList = React.lazy(
  () => import('base/admin/VariantTypes/VariantTypeList')
);
const BarcodeCheckins = React.lazy(
  () => import('base/barcodeCheckins')
);
const Calendar = React.lazy(
  () => import('base/calendar/Calendar')
);
const Calendar_CalendarDetail = React.lazy(
  () => import('base/calendar/Calendar/views/CalendarDetail')
);
const Calendar_EventNew = React.lazy(
  () => import('base/calendar/Calendar/views/EventNew')
);
const Calendar_EventDetail = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail')
);
const EventDetail_Info = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/Info')
);
const EventDetail_PricingTiers = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/PricingTiers')
);
const EventDetail_TicketTypes = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/TicketTypes')
);
const EventDetail_EntranceRequirements = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/EntranceRequirements')
);
const EventDetail_Activities = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/Activities')
);
const EventDetail_Guests = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/Guests')
);
/*const EventDetail_Checkins = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/Checkins')
);*/
const EventDetail_RelatedEvents = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/RelatedEvents')
);
const EventDetail_Forms = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/Forms')
);
const EventDetail_ReminderTemplates = React.lazy(
  () => import('base/calendar/Calendar/views/EventDetail/views/ReminderTemplates')
);
const Calendar_BookingDetail = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail')
);
const BookingDetail_ScheduleSummary = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/ScheduleSummary')
);
const BookingDetail_Info = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/Info')
);
const BookingDetail_TicketTypes = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/TicketTypes')
);
const BookingDetail_EntranceRequirements = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/EntranceRequirements')
);
const BookingDetail_Activities = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/Activities')
);
const BookingDetail_Guests = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/Guests')
);
const BookingDetail_Checkins = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/Checkins')
);
const BookingDetail_Notes = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/Notes')
);
const BookingDetail_Invoicing = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/Invoicing')
);
const BookingDetail_Forms = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/Forms')
);
const BookingDetail_ReminderTemplates = React.lazy(
  () => import('base/calendar/Calendar/views/BookingDetail/views/ReminderTemplates')
);
const Calendar_CalEventDetail = React.lazy(
  () => import('base/calendar/Calendar/views/CalEventDetail')
);
const Events = React.lazy(
  () => import('base/calendar/Events')
);
const Bookings = React.lazy(
  () => import('base/calendar/Bookings')
);
const Support_HistoryDetail = React.lazy(
  () => import('base/chat/Support/History/HistoryDetail')
);
const Support_HistoryList = React.lazy(
  () => import('base/chat/Support/History/HistoryList')
);
const Support_IssueTypeNew = React.lazy(
  () => import('base/chat/Support/IssueTypes/IssueTypeNew')
);
const Support_IssueTypeDetail = React.lazy(
  () => import('base/chat/Support/IssueTypes/IssueTypeDetail')
);
const Support_IssueTypeList = React.lazy(
  () => import('base/chat/Support/IssueTypes/IssueTypeList')
);
const Support_Requests = React.lazy(
  () => import('base/chat/Support/Requests')
);
const Support_Request_Detail = React.lazy(
  () => import('base/chat/Support/Requests/RequestDetail/RequestDetail')
);
const CheckinDetail = React.lazy(
  () => import('base/crm/Checkin/CheckinDetail')
);
const CheckinList = React.lazy(
  () => import('base/crm/Checkin/CheckinList')
);
const CustomerNew = React.lazy(
  () => import('base/crm/Customer/CustomerNew')
);
const CustomerDetail = React.lazy(
  () => import('base/crm/Customer/CustomerDetail')
);
const CustomerDetail_Profile = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Profile')
);
const CustomerDetail_Notes = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Notes')
);
const CustomerDetail_BillingNotifications = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/BillingNotifications')
);
// const CustomerDetail_MarketingCenter = React.lazy(
//   () => import('base/crm/Customer/CustomerDetail/views/MarketingCenter')
// );
const CustomerDetail_MarketingNotifications = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/MarketingNotifications')
);
// const CustomerDetail_Bookings = React.lazy(
//   () => import('base/crm/Customer/CustomerDetail/views/Bookings')
// );
const CustomerDetail_CheckinDetail = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/CheckinDetail')
);
const CustomerDetail_Checkins = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Checkins')
);
const CustomerDetail_Household = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Household')
);
const CustomerDetail_Certifications = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Certifications')
);
const CustomerDetail_MembershipDetail = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/MembershipDetail')
);
const CustomerDetail_PassDetail = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/PassDetail')
);
const CustomerDetail_TicketDetail = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/TicketDetail')
);
const CustomerDetail_MyPlans = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/MyPlans')
);
const CustomerDetail_ManagedPlans = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/ManagedPlans')
);
const CustomerDetail_MyGiftCards = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/MyGiftCards')
);
const CustomerDetail_MyReservations = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/MyReservations')
);
const CustomerDetail_HouseholdReservations = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/HouseholdReservations')
);
const CustomerDetail_StoreCredit = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/StoreCredit')
);
const CustomerDetail_PointsDetail = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/PointsDetail')
);
const CustomerDetail_Points = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Points')
);
const CustomerDetail_RedeemPoints = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/RedeemPoints')
);
const CustomerDetail_PurchasePlans = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/PurchasePlans')
);
const CustomerDetail_OrderDetail = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/OrderDetail')
);
const CustomerDetail_Orders = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Orders')
);
const CustomerDetail_PaymentCards = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/PaymentCards')
);
const CustomerDetail_GiftCards = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/GiftCards')
);
const CustomerDetail_Waivers = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Waivers')
);
const CustomerDetail_SupportDetail = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/SupportDetail')
);
const CustomerDetail_Support = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/Support')
);
const CustomerDetail_EmailDefaultWaiver = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/EmailDefaultWaiver')
);
const CustomerDetail_EmailDocument = React.lazy(
  () => import('base/crm/Customer/CustomerDetail/views/EmailDocument')
);
const CustomerList = React.lazy(
  () => import('base/crm/Customer/CustomerList')
);
const GroupNew = React.lazy(
  () => import('base/crm/Group/GroupNew')
);
const GroupDetail = React.lazy(
  () => import('base/crm/Group/GroupDetail')
);
const GroupList = React.lazy(
  () => import('base/crm/Group/GroupList')
);
const MergeAccounts = React.lazy(
  () => import('base/crm/MergeAccounts')
);
const Dashboard = React.lazy(
  () => import('base/dashboard')
);
const TransactionDetail = React.lazy(
  () => import('base/finances/Transactions/TransactionDetail')
);
const TransactionList = React.lazy(
  () => import('base/finances/Transactions/TransactionList')
);
const MembershipDetail = React.lazy(
  () => import('base/finances/Memberships/MembershipDetail')
);
const MembershipList = React.lazy(
  () => import('base/finances/Memberships/MembershipList')
);
const RevenueCategoryReportNew = React.lazy(
  () => import('base/finances/RevenueCategoryReports/RevenueCategoryReportNew')
);
const RevenueCategoryReportDetail = React.lazy(
  () => import('base/finances/RevenueCategoryReports/RevenueCategoryReportDetail')
);
const RevenueCategoryReportDetail_Report = React.lazy(
  () => import('base/finances/RevenueCategoryReports/RevenueCategoryReportDetail/views/Report')
);
const RevenueCategoryReportDetail_Info = React.lazy(
  () => import('base/finances/RevenueCategoryReports/RevenueCategoryReportDetail/views/Info')
);
const RevenueCategoryReportDetail_Quickbooks = React.lazy(
  () => import('base/finances/RevenueCategoryReports/RevenueCategoryReportDetail/views/Quickbooks')
);
const RevenueCategoryReportList = React.lazy(
  () => import('base/finances/RevenueCategoryReports/RevenueCategoryReportList')
);
const QuickbooksConnectionNew = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionNew')
);
const QuickbooksConnectionDetail = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionDetail')
);
const QuickbooksConnectionDetail_Info = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionDetail/views/Info')
);
const QuickbooksConnectionDetail_Connection = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionDetail/views/Connection')
);
const QuickbooksConnectionDetail_Classes = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionDetail/views/Classes')
);
const QuickbooksConnectionDetail_Mapping = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionDetail/views/Mapping')
);
const QuickbooksConnectionDetail_MappingEdit = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionDetail/views/MappingEdit')
);
const QuickbooksConnectionDetail_LedgerList = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionDetail/views/LedgerList')
);
const QuickbooksConnectionList = React.lazy(
  () => import('base/finances/QuickbooksConnections/QuickbooksConnectionList')
);
const AchTransactions = React.lazy(
  () => import('base/finances/AchTransactions')
);
const QuestionNew = React.lazy(
  () => import('base/forms/Questions/QuestionNew')
);
const QuestionDetail = React.lazy(
  () => import('base/forms/Questions/QuestionDetail')
);
const QuestionList = React.lazy(
  () => import('base/forms/Questions/QuestionList')
);
const SubmissionPreview = React.lazy(
  () => import('base/forms/Submissions/SubmissionPreview')
);
const SubmissionDetail = React.lazy(
  () => import('base/forms/Submissions/SubmissionDetail')
);
const SubmissionList = React.lazy(
  () => import('base/forms/Submissions/SubmissionList')
);
const TemplateNew = React.lazy(
  () => import('base/forms/Templates/TemplateNew')
);
const TemplateDetail = React.lazy(
  () => import('base/forms/Templates/TemplateDetail')
);
const TemplateDetail_Info = React.lazy(
  () => import('base/forms/Templates/TemplateDetail/views/Info')
);
const TemplateDetail_FormContent = React.lazy(
  () => import('base/forms/Templates/TemplateDetail/views/FormContent')
);
const TemplateList = React.lazy(
  () => import('base/forms/Templates/TemplateList')
);
const MerchantTrack = React.lazy(
  () => import('../app/MerchantTrack')
);
const Points_RedemptionList = React.lazy(
  () => import('base/points/RedemptionList')
);
const Points_StaffAdjustmentList = React.lazy(
  () => import('base/points/StaffAdjustmentList')
);
const Shop = React.lazy(
  () => import('base/store/Shop')
);
const Shop_Scanner = React.lazy(
  () => import('base/store/Shop/views/Scanner')
);
const Shop_Products = React.lazy(
  () => import('base/store/Shop/views/Products')
);
const Orders = React.lazy(
  () => import('base/store/Orders')
);
const GiftCardDetail = React.lazy(
  () => import('base/store/GiftCards/GiftCardDetail')
);
const GiftCardList = React.lazy(
  () => import('base/store/GiftCards/GiftCardList')
);
const CashDrawer = React.lazy(
  () => import('base/store/CashDrawer')
);
const DrawerDetail = React.lazy(
  () => import('base/store/CashDrawer/views/DrawerDetail')
);
const DrawerHistory = React.lazy(
  () => import('base/store/CashDrawer/views/DrawerHistory')
);
const CurrentDrawer = React.lazy(
  () => import('base/store/CashDrawer/views/CurrentDrawer')
);


const getRouteConfigFromProps = ({
  activeCart, activeCartCustomer, activeUsers,
  areRevenueCategoriesRequired,
  cancelOrder, checkoutOrderOnlyAtLocationOfPurchase,
  createNewCart, completeCart, confirmAction,
  currentDevice, currentLocation, currentStaff, currentUser,
  device, defaultPlansView, focusOnCustomerCart,
  isAdHocChargeActive, isCartLoading, isPointsActive, isStoreCreditActive,
  newOrderPayment, paymentStatus,
  putCartDiscount, putCartPromoCode, putItemsInCart, putItemsInCustomerCart,
  refundItem, removePromoCodeFromCart, resetPaymentStatus,
  rightDrawer, setAddCardDialogCallback, setCheckinDialogCallback, setCheckinSettings,
  setCurrentLocation, setCurrentStaff, setCurrentUser, setDefaultSettings, setGeneralSettings,
  setPaymentStatus, setRefundDialogCallback, setRightDrawerView, setSounds,
  showAddCardDialog, showCheckinDialogCustomer, showCheckinDialogSearch,
  showCopyToClipboardDialog, showCreateAdHocChargeDialog,
  showNewGiftCardDialog, showRefundOrderDialog,
  terminateOrderPayment, timeZone, toggleCustomerDialog, toggleRightDrawer,
  userImageHasChanged,
}) => ([
  {
    name: 'LOGIN',
    relPath: '/login',
    component: Login,
    propsToPass: {
      activeUsers,
      currentUser,
      privacyPolicy: AppConfig.privacyPolicy,
      setCurrentUser,
      termsOfUse: AppConfig.termsOfUse,
    },
  },
  {
    name: 'RESET_PASSWORD',
    relPath: '/reset-password',
    component: ResetPassword,
  },
  {
    name: 'AUTH',
    relPath: '',
    component: AuthApp,
    routeComponent: AuthRoute,
    subroutes: [
      {
        name: 'STRIPE_CALLBACK',
        relPath: '/stripe-callback',
        component: StripeCallback,
        propsToPass: {
          currentLocation,
          setCurrentLocation,
        },
      },
      {
        name: 'REPORTS',
        relPath: '/reports',
        component: Reports,
        permission: SECTIONS.STORE,
        subroutes: [
          {
            name: 'REPORT_GOBD',
            relPath: '/gobd',
            component: GoBD,
            propsToPass: {
              currentLocation,
            },
          },
        ],
      },
      {
        name: 'PROFILE',
        relPath: '/profile',
        component: Profile,
        wrapper: MinimalLayout,
        subroutes: [
          {
            name: 'PROFILE_INFO',
            relPath: '/info',
            component: Profile_Info,
            icon: PersonIcon,
            label: 'Profile',
            propsToPass: {
              currentLocation,
              timeZone,
              confirmAction,
              userImageHasChanged,
            },
          },
          {
            name: 'PROFILE_SETTINGS',
            label: 'Settings',
            relPath: '/settings',
            component: Profile_Settings,
            icon: SettingsIcon,
            propsToPass: {
              setCurrentUser,
            },
          },
          {
            name: 'PROFILE_DEFAULTS',
            label: 'Defaults',
            relPath: '/defaults',
            component: Profile_Defaults,
            icon: BusinessIcon,
          },
        ],
        propsToPass: {
          currentUser,
          currentLocation,
          currentDevice,
          setCurrentStaff,
        },
      },
      {
        name: 'CHECKOUT',
        relPath: '/checkout',
        component: Checkout,
        permission: SECTIONS.CHECKOUT,
        wrapper: MinimalLayout,
        propsToPass: {
          activeCart,
          cartComponent: Cart,
          cartTotalsComponent: CartTotals,
          checkinAfterPurchaseItemTypes: CHECKIN_AFTER_PURCHASE_ITEM_TYPES,
          completeCart,
          confirmAction,
          currentLocation,
          currentStaff,
          device,
          isCartLoading,
          isStoreCreditActive,
          newOrderPayment,
          paymentStatus,
          putCartDiscount,
          putCartPromoCode,
          removePromoCodeFromCart,
          resetPaymentStatus,
          setAddCardDialogCallback,
          setPaymentStatus,
          showAddCardDialog,
          terminateOrderPayment,
        },
      },
      {
        name: 'SETTINGS',
        relPath: '/settings',
        component: Settings,
        permission: SECTIONS.SETTINGS,
        wrapper: MinimalLayout,
        subroutes: [
          {
            name: 'SETTINGS_GENERAL',
            relPath: '/general',
            component: Settings_General,
            label: 'General',
            propsToPass: {
              setGeneralSettings,
            },
          },
          {
            name: 'SETTINGS_DEFAULT',
            relPath: '/defaults',
            component: Settings_Default,
            label: 'Defaults',
            propsToPass: {
              setDefaultSettings,
            },
          },
          {
            name: 'SETTINGS_PORTAL',
            relPath: '/portal',
            component: Settings_Portal,
            label: 'Portal',
            propsToPass: {
              currentLocation,
            },
          },
          {
            name: 'SETTINGS_CHECKINS',
            relPath: '/checkins',
            component: Settings_Checkins,
            label: 'Checkins',
            propsToPass: {
              currentLocation,
              setCheckinSettings,
            },
          },
          {
            name: 'SETTINGS_EMAIL',
            relPath: '/email',
            label: 'Email',
            subroutes: [
              {
                name: 'SETTINGS_EMAIL_SENDER',
                relPath: '/sender',
                component: Settings_EmailSender,
                label: 'Sender',
              },
              {
                name: 'SETTINGS_EMAIL_RECEIPTS',
                relPath: '/receipts',
                component: Settings_EmailReceipts,
                label: 'Receipts',
              },
              {
                name: 'SETTINGS_EMAIL_MARKETING',
                relPath: '/marketing',
                component: Settings_EmailMarketing,
                label: 'Marketing',
              },
            ],
          },
          {
            name: 'SETTINGS_POINTS',
            relPath: '/points',
            component: Settings_Points,
            label: 'Loyalty Points',
            propsToPass: {
              currentLocation,
            },
          },
          {
            name: 'SETTINGS_SOUNDS',
            relPath: '/sounds',
            component: Settings_Sounds,
            label: 'Sounds',
            propsToPass: {
              currentLocation,
              setSounds,
            },
          },
        ],
      },
      {
        name: 'MAIN',
        relPath: '',
        component: Main2,
        subroutes: [
          {
            name: 'ADMIN',
            relPath: '/admin',
            component: SwitchWithRoutes,
            propsForAll: {
              breadcrumb1: 'Admin',
            },
            subroutes: [
              {
                name: 'CATEGORIES',
                relPath: '/categories',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.CALENDAR_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Categories',
                },
                subroutes: [
                  {
                    name: 'CATEGORY_NEW',
                    relPath: '/new',
                    component: ActivityNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'CATEGORY_DETAIL',
                    relPath: '/:id',
                    component: ActivityDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'CATEGORY_LIST',
                    relPath: '',
                    component: ActivityList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'CALENDARS',
                relPath: '/calendars',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.CALENDAR_ADMIN,
                passthroughProps: true,
                propsToPass: {
                  breadcrumb2: 'Calendars',
                },
                subroutes: [
                  {
                    name: 'CALENDAR_MERGE',
                    relPath: '/merge',
                    component: CalendarMerge,
                    permission: SECTIONS.CALENDAR_ADMIN,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                  {
                    name: 'CALENDAR_NEW',
                    relPath: '/new',
                    component: CalendarNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'CALENDAR_DETAIL',
                    relPath: '/:id',
                    component: CalendarDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'CALENDAR_LIST',
                    relPath: '',
                    component: CalendarList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'CERTIFICATIONS',
                relPath: '/certifications',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Certifications',
                },
                subroutes: [
                  {
                    name: 'CERTIFICATION_NEW',
                    relPath: '/new',
                    component: CertificationNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'CERTIFICATION_DETAIL',
                    relPath: '/:id',
                    component: CertificationDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'CERTIFICATION_LIST',
                    relPath: '',
                    component: CertificationList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'DOCUMENT_TEMPLATES',
                relPath: '/document-templates',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.ADMIN,
                passthroughProps: true,
                propsToPass: {
                  breadcrumb2: 'Document Templates',
                },
                subroutes: [
                  {
                    name: 'DOCUMENT_TEMPLATE_NEW',
                    relPath: '/new',
                    component: DocumentTemplateNewEdit,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'DOCUMENT_TEMPLATE_EDIT',
                    relPath: '/:id/edit',
                    component: DocumentTemplateNewEdit,
                  },
                  {
                    name: 'DOCUMENT_TEMPLATE_LIST',
                    relPath: '',
                    component: DocumentTemplatesList,
                    propsToPass: {
                      timeZone,
                    },
                  },
                ],
              },
              {
                name: 'REMINDER_TEMPLATES',
                relPath: '/reminder-templates',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.CALENDAR_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Reminder Templates',
                  currentLocation,
                  timeZone,
                },
                subroutes: [
                  {
                    name: 'REMINDER_TEMPLATE_NEW',
                    relPath: '/new',
                    component: ReminderTemplateNew,
                  },
                  {
                    name: 'REMINDER_TEMPLATE_DETAIL',
                    relPath: '/:id',
                    component: ReminderTemplateDetail,
                  },
                  {
                    name: 'REMINDER_TEMPLATE_LIST',
                    relPath: '',
                    component: ReminderTemplatesList,
                  },
                ],
              },
              {
                name: 'GIFT_CARD_TYPES',
                relPath: '/gift-card-types',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Gift Card Types',
                },
                subroutes: [
                  {
                    name: 'GIFT_CARD_TYPE_NEW',
                    relPath: '/new',
                    component: GiftCardTypeNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'GIFT_CARD_TYPE_DETAIL',
                    relPath: '/:id',
                    component: GiftCardTypeDetail,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                      showCopyToClipboardDialog,
                    },
                  },
                  {
                    name: 'GIFT_CARD_TYPE_LIST',
                    relPath: '',
                    component: GiftCardTypeList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'INVENTORY_LOGS',
                relPath: '/inventory-logs',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Inventory Logs',
                },
                subroutes: [
                  {
                    name: 'INVENTORY_LOG_NEW',
                    relPath: '/new',
                    component: InventoryLogNew,
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                    },
                  },
                  {
                    name: 'INVENTORY_LOG_DETAIL',
                    relPath: '/:id',
                    component: InventoryLogDetail,
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                    },
                  },
                  {
                    name: 'INVENTORY_LOG_LIST',
                    relPath: '',
                    component: InventoryLogList,
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                    },
                  },
                ],
              },
              {
                name: 'LOCATIONS',
                relPath: '/locations',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Locations',
                },
                subroutes: [
                  {
                    name: 'LOCATION_NEW',
                    relPath: '/new',
                    component: LocationNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'LOCATION_DETAIL',
                    relPath: '/:id',
                    component: LocationDetail,
                    propsToPass: {
                      currentLocation,
                      setCurrentLocation,
                    },
                    subroutes: [
                      {
                        name: 'LOCATION_INFO',
                        relPath: '',
                        exact: true,
                        component: LocationDetail_Info,
                        icon: ListAltIcon,
                        label: 'General Info',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'LOCATION_DEVICES',
                        relPath: '/devices',
                        component: SwitchWithRoutes,
                        passthroughProps: true,
                        subroutes: [
                          {
                            name: 'LOCATION_DEVICE_NEW',
                            relPath: '/new',
                            component: LocationDetail_DeviceNew,
                            hide: true,
                            propsToPass: {
                              areRevenueCategoriesRequired,
                              currentLocation,
                              timeZone,
                            },
                          },
                          {
                            name: 'LOCATION_DEVICE_DETAIL',
                            relPath: '/:deviceId',
                            component: LocationDetail_DeviceDetail,
                            hide: true,
                            propsToPass: {
                              currentLocation,
                              timeZone,
                            },
                          },
                          {
                            name: 'LOCATION_DEVICE_LIST',
                            relPath: '',
                            component: LocationDetail_DeviceList,
                            label: 'Devices',
                            icon: PaymentIcon,
                          },
                        ],
                      },
                      {
                        name: 'LOCATION_STAFF',
                        relPath: '/staff',
                        component: SwitchWithRoutes,
                        passthroughProps: true,
                        subroutes: [
                          {
                            name: 'LOCATION_STAFF_NEW',
                            relPath: '/new',
                            component: LocationDetail_StaffNew,
                            hide: true,
                            propsToPass: {
                              areRevenueCategoriesRequired,
                              currentLocation,
                              timeZone,
                            },
                          },
                          {
                            name: 'LOCATION_STAFF_LIST',
                            relPath: '',
                            component: LocationDetail_StaffList,
                            label: 'Staff',
                            icon: PeopleOutlineIcon,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'LOCATION_LIST',
                    relPath: '',
                    component: LocationList,
                  },
                ],
              },
              {
                name: 'MEMBERSHIP_MANAGEMENT',
                relPath: '/membership-management',
                component: MembershipManagement,
                permission: SECTIONS.MEMBERSHIP_MANAGEMENT,
                propsToPass: {
                  currentLocation,
                  confirmAction,
                },
              },
              {
                name: 'MEMBERSHIP_RESUME_LIST',
                relPath: '/membership-resume-list',
                component: MembershipResumeList,
                permission: SECTIONS.MEMBERSHIP_MANAGEMENT,
                propsToPass: {
                  currentLocation,
                  rightDrawer,
                  timeZone,
                  putItemsInCustomerCart,
                  setRightDrawerView,
                  toggleRightDrawer,
                },
              },
              {
                name: 'MEMBERSHIP_HISTORY',
                relPath: '/membership-history',
                component: MembershipHistory,
                permission: PERMISSIONS.VIEW_MEMBERSHIP_HISTORY,
                propsToPass: {
                  currentLocation,
                  rightDrawer,
                  timeZone,
                  putItemsInCustomerCart,
                  setRightDrawerView,
                  toggleRightDrawer,
                },
              },
              {
                name: 'MEMBERSHIP_TYPES',
                relPath: '/membership-types',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Membership Types',
                },
                subroutes: [
                  {
                    name: 'MEMBERSHIP_TYPE_NEW',
                    relPath: '/new',
                    component: MembershipTypeNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'MEMBERSHIP_TYPE_DETAIL',
                    relPath: '/:id',
                    component: MembershipTypeDetail,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      currentStaff,
                      timeZone,
                      showCopyToClipboardDialog,
                    },
                  },
                  {
                    name: 'MEMBERSHIP_TYPE_LIST',
                    relPath: '',
                    component: MembershipTypeList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'PASS_TYPES',
                relPath: '/pass-types',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Pass Types',
                },
                subroutes: [
                  {
                    name: 'PASS_TYPE_NEW',
                    relPath: '/new',
                    component: PassTypeNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      isPointsActive,
                      timeZone,
                    },
                  },
                  {
                    name: 'PASS_TYPE_DETAIL',
                    relPath: '/:id',
                    component: PassTypeDetail,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      currentStaff,
                      isPointsActive,
                      timeZone,
                      showCopyToClipboardDialog,
                    },
                  },
                  {
                    name: 'PASS_TYPE_LIST',
                    relPath: '',
                    component: PassTypeList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'PRICING_TIERS',
                relPath: '/pricing-tiers',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.CALENDAR_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Pricing Tiers',
                },
                subroutes: [
                  {
                    name: 'PRICING_TIER_NEW',
                    relPath: '/new',
                    component: PricingTierNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'PRICING_TIER_DETAIL',
                    relPath: '/:id',
                    component: PricingTierDetail,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'PRICING_TIER_LIST',
                    relPath: '',
                    component: PricingTierList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'PRODUCTS',
                relPath: '/products',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Products',
                },
                subroutes: [
                  {
                    name: 'PRODUCT_NEW',
                    relPath: '/new',
                    component: ProductNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      isPointsActive,
                      timeZone,
                    },
                  },
                  {
                    name: 'PRODUCT_DETAIL',
                    relPath: '/:id',
                    component: ProductDetail,
                    propsToPass: {
                      confirmAction,
                      currentLocation,
                    },
                    subroutes: [
                      {
                        name: 'PRODUCT_INFO',
                        relPath: '',
                        exact: true,
                        component: ProductDetail_Info,
                        label: 'General',
                        icon: InfoOutlinedIcon,
                        propsToPass: {
                          areRevenueCategoriesRequired,
                          currentLocation,
                          currentStaff,
                          isPointsActive,
                          timeZone,
                        },
                      },
                      {
                        name: 'PRODUCT_INVENTORY_ADJUSTMENT',
                        relPath: '/inventory-adjustment',
                        component: ProductDetail_InventoryAdjustment,
                        propsToPass: {
                          currentLocation,
                        },
                        hide: true,
                      },
                      {
                        name: 'PRODUCT_VARIANTS',
                        relPath: '/variants',
                        component: SwitchWithRoutes,
                        passthroughProps: true,
                        subroutes: [
                          {
                            name: 'PRODUCT_VARIANTS_SETUP',
                            relPath: '/setup',
                            component: ProductDetail_VariantsSetup,
                            propsToPass: {
                              currentLocation,
                              timeZone,
                            },
                            hide: true,
                          },
                          {
                            name: 'PRODUCT_VARIANTS_EDIT',
                            relPath: '/edit',
                            component: ProductDetail_VariantsEdit,
                            fullScreen: true,
                            propsToPass: {
                              currentLocation,
                              skeletonAnimation: SKELETON_ANIMATION,
                            },
                            hide: true,
                          },
                          {
                            name: 'PRODUCT_VARIANTS_LIST',
                            relPath: '',
                            component: ProductDetail_Variants,
                            icon: FilterBAndWIcon,
                            label: 'Product Management',
                            propsToPass: {
                              currentLocation,
                            },
                          },
                        ],
                      },
                      {
                        name: 'PRODUCT_INVENTORY',
                        relPath: '/inventory',
                        component: ProductDetail_Inventory,
                        icon: AssignmentIcon,
                        label: 'Inventory',
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          skeletonAnimation: SKELETON_ANIMATION,
                        },
                      },
                    ],
                  },
                  {
                    name: 'PRODUCT_LIST',
                    relPath: '',
                    component: ProductList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'PROMO_CODES',
                relPath: '/promo-codes',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE_ADMIN,
                passthroughProps: true,
                propsToPass: {
                  breadcrumb2: 'Promo Codes',
                },
                subroutes: [
                  {
                    name: 'PROMO_CODE_NEW',
                    relPath: '/new',
                    component: PromoCodeNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'PROMO_CODE_DETAIL',
                    relPath: '/:id',
                    component: PromoCodeDetail,
                    subroutes: [
                      {
                        name: 'PROMO_CODE_INFO',
                        relPath: '',
                        exact: true,
                        component: PromoCodeDetail_Info,
                        label: 'View',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'PROMO_CODE_APPLICABLE_ITEMS',
                        relPath: '/applicable-items',
                        component: PromoCodeDetail_ApplicableItems,
                        label: 'Applicable Items',
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          promoCodeItemTypes: PROMO_CODE_ITEM_TYPES,
                        },
                      },
                    ],
                  },
                  {
                    name: 'PROMO_CODE_LIST',
                    relPath: '',
                    component: PromoCodeList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'REVENUE_CATEGORIES',
                relPath: '/revenue-categories',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE_ADMIN,
                passthroughProps: true,
                propsToPass: {
                  breadcrumb2: 'Revenue Categories',
                },
                subroutes: [
                  {
                    name: 'REVENUE_CATEGORY_NEW',
                    relPath: '/new',
                    component: RevenueCategoryNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'REVENUE_CATEGORY_DETAIL',
                    relPath: '/:id',
                    component: RevenueCategoryDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'REVENUE_CATEGORY_LIST',
                    relPath: '',
                    component: RevenueCategoryList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'STAFF',
                relPath: '/staff',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Staff',
                },
                subroutes: [
                  {
                    name: 'STAFF_NEW',
                    relPath: '/new',
                    component: StaffNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'STAFF_DETAIL',
                    relPath: '/:id',
                    component: StaffDetail,
                    propsToPass: {
                      confirmAction,
                    },
                    subroutes: [
                      {
                        name: 'STAFF_CERTIFICATIONS',
                        relPath: '/certifications',
                        component: StaffDetail_Certifications,
                        label: 'Certifications',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                          confirmAction,
                          routeComponent: PrivateRoute,
                        },
                      },
                      {
                        name: 'STAFF_PROFILE',
                        relPath: '',
                        component: StaffDetail_Profile,
                        icon: PersonIcon,
                        label: 'Profile',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                    ],
                  },
                  {
                    name: 'STAFF_LIST',
                    relPath: '',
                    component: StaffList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'STAFF_ROLES',
                relPath: '/staff-roles',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Staff Roles',
                },
                subroutes: [
                  {
                    name: 'STAFF_ROLE_EDIT',
                    relPath: '/:id/edit',
                    component: StaffRoleEdit,
                  },
                  {
                    name: 'STAFF_ROLE_LIST',
                    relPath: '',
                    component: StaffRoleList,
                  },
                ],
              },
              {
                name: 'TICKET_TYPES',
                relPath: '/ticket-types',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.CALENDAR_ADMIN,
                passthroughProps: true,
                propsToPass: {
                  breadcrumb2: 'Ticket Types',
                },
                subroutes: [
                  {
                    name: 'TICKET_TYPE_NEW',
                    relPath: '/new',
                    component: TicketTypeNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'TICKET_TYPE_DETAIL',
                    relPath: '/:id',
                    component: TicketTypeDetail,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'TICKET_TYPE_LIST',
                    relPath: '',
                    component: TicketTypeList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'VARIANT_TYPES',
                relPath: '/variant-types',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE_ADMIN,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Variant Types',
                },
                subroutes: [
                  {
                    name: 'VARIANT_TYPE_NEW',
                    relPath: '/new',
                    component: VariantTypeNew,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'VARIANT_TYPE_DETAIL',
                    relPath: '/:id',
                    component: VariantTypeDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                    subroutes: [
                      {
                        name: 'VARIANT_TYPE_VARIANT_OPTIONS',
                        relPath: '/variant-options',
                        component: VariantTypeDetail_VariantOptions,
                        label: 'Variant Options',
                      },
                      {
                        name: 'VARIANT_TYPE_INFO',
                        relPath: '',
                        component: VariantTypeDetail_Info,
                        label: 'Info',
                      },
                    ],
                  },
                  {
                    name: 'VARIANT_TYPE_LIST',
                    relPath: '',
                    component: VariantTypeList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
            ],
          },
          {
            name: 'BARCODE_CHECKINS',
            relPath: '/barcode-checkins',
            component: BarcodeCheckins,
            propsToPass: {
              barcodeMinLength: BARCODE_MIN_LENGHT,
              currentLocation,
            },
          },
          {
            name: 'CALENDARING',
            relPath: '/calendar',
            component: SwitchWithRoutes,
            permission: SECTIONS.CALENDAR,
            subroutes: [
              {
                name: 'EVENTS',
                relPath: '/events',
                component: Events,
                permission: SECTIONS.CALENDAR,
                propsToPass: {
                  currentLocation,
                  timeZone,
                },
              },
              {
                name: 'BOOKINGS',
                relPath: '/bookings',
                component: Bookings,
                permission: SECTIONS.CALENDAR,
                propsToPass: {
                  currentLocation,
                  timeZone,
                },
              },
              {
                name: 'CALENDAR',
                relPath: '',
                component: Calendar,
                permission: SECTIONS.CALENDAR,
                propsToPass: {
                  currentLocation,
                  currentStaff,
                },
                subroutes: [
                  {
                    name: 'EVENT',
                    relPath: '/event',
                    component: SwitchWithRoutes,
                    passthroughProps: true,
                    subroutes: [
                      {
                        name: 'EVENT_NEW',
                        relPath: '/new',
                        component: Calendar_EventNew,
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                        },
                      },
                      {
                        name: 'EVENT_DETAIL',
                        relPath: '/:eventId',
                        component: Calendar_EventDetail,
                        propsToPass: {
                          activeCart,
                          currentLocation,
                          timeZone,
                          currentStaff,
                          rightDrawer,
                          confirmAction,
                          putItemsInCart,
                          refundItem,
                          setRefundDialogCallback,
                          setRightDrawerView,
                          showCopyToClipboardDialog,
                          toastDuration: TOAST_DURATION,
                          toggleCustomerDialog,
                          toggleRightDrawer,
                        },
                        subroutes: [
                          {
                            name: 'EVENT_INFO',
                            relPath: '',
                            exact: true,
                            component: EventDetail_Info,
                            label: 'Event Info',
                            icon: InfoIcon,
                          },
                          {
                            name: 'EVENT_PRICING_TIERS',
                            relPath: '/pricing-tiers',
                            component: EventDetail_PricingTiers,
                            label: 'Pricing Tiers',
                            icon: SortIcon,
                          },
                          {
                            name: 'EVENT_TICKET_TYPES',
                            relPath: '/ticket-types',
                            component: EventDetail_TicketTypes,
                            label: 'Ticket Types',
                            icon: BookmarksOutlinedIcon,
                          },
                          {
                            name: 'EVENT_ENTRANCE_REQUIREMENTS',
                            relPath: '/entrance-requirements',
                            component: EventDetail_EntranceRequirements,
                            label: 'Entrance Requirements',
                            icon: ExitToAppIcon,
                          },
                          {
                            name: 'EVENT_CATEGORIES',
                            relPath: '/activities',
                            component: EventDetail_Activities,
                            label: 'Categories',
                            icon: AccessibilityNewIcon,
                          },
                          {
                            name: 'EVENT_GUESTS',
                            relPath: '/guests',
                            component: EventDetail_Guests,
                            label: 'Guests',
                            icon: PeopleOutlineIcon,
                          },
                          /*
                          {
                            name: 'EVENT_CHECKINS',
                            relPath: '/checkins',
                            component: EventDetail_Checkins,
                            label: 'Checkins',
                            icon: HowToRegOutlinedIcon,
                          },*/
                          {
                            name: 'EVENT_RELATED_BOOKINGS',
                            relPath: '/related-events',
                            component: EventDetail_RelatedEvents,
                            label: 'Related Bookings',
                            icon: ListAltIcon,
                          },
                          {
                            name: 'EVENT_FORMS',
                            relPath: '/forms',
                            component: EventDetail_Forms,
                            label: 'Forms',
                            icon: DescriptionOutlinedIcon,
                          },
                          {
                            name: 'EVENT_REMINDER_TEMPLATES',
                            relPath: '/reminder-templates',
                            component: EventDetail_ReminderTemplates,
                            label: 'Reminder Templates',
                            icon: EmailOutlined,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'BOOKING',
                    relPath: '/booking',
                    component: SwitchWithRoutes,
                    passthroughProps: true,
                    subroutes: [
                      {
                        name: 'BOOKING_DETAIL',
                        relPath: '/:bookingId',
                        component: Calendar_BookingDetail,
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          rightDrawer,
                          timeZone,
                          confirmAction,
                          refundItem,
                          putItemsInCart,
                          putItemsInCustomerCart,
                          setCheckinDialogCallback,
                          setRefundDialogCallback,
                          setRightDrawerView,
                          showCheckinDialogSearch,
                          showCheckinDialogCustomer,
                          showCopyToClipboardDialog,
                          toastDuration: TOAST_DURATION,
                          toggleCustomerDialog,
                          toggleRightDrawer,
                        },
                        subroutes: [
                          {
                            name: 'BOOKING_EVENT_SUMMARY',
                            relPath: '/schedule-summary',
                            component: BookingDetail_ScheduleSummary,
                            label: 'Event Summary',
                          },
                          {
                            name: 'BOOKING_INFO',
                            relPath: '',
                            exact: true,
                            component: BookingDetail_Info,
                            label: 'Info',
                            icon: InfoIcon,
                          },
                          {
                            name: 'BOOKING_TICKET_TYPES',
                            relPath: '/ticket-types',
                            component: BookingDetail_TicketTypes,
                            label: 'Ticket Types',
                            icon: BookmarksOutlinedIcon,
                          },
                          {
                            name: 'BOOKING_ENTRANCE_REQUIREMENTS',
                            relPath: '/entrance-requirements',
                            component: BookingDetail_EntranceRequirements,
                            label: 'Entrance Requirements',
                            icon: ExitToAppIcon,
                          },
                          {
                            name: 'BOOKING_CATEGORIES',
                            relPath: '/activities',
                            component: BookingDetail_Activities,
                            label: 'Categories',
                            icon: AccessibilityNewIcon,
                          },
                          {
                            name: 'BOOKING_GUESTS',
                            relPath: '/guests',
                            component: BookingDetail_Guests,
                            label: 'Guests',
                            icon: PeopleOutlineIcon,
                          },
                          {
                            name: 'BOOKING_CHECKINS',
                            relPath: '/checkins',
                            component: BookingDetail_Checkins,
                            label: 'Checkins',
                            icon: HowToRegOutlinedIcon,
                          },
                          {
                            name: 'BOOKING_NOTES',
                            relPath: '/notes',
                            component: BookingDetail_Notes,
                            label: 'Notes',
                            icon: NotesIcon,
                          },
                          {
                            name: 'BOOKING_INVOICING',
                            relPath: '/invoicing',
                            component: BookingDetail_Invoicing,
                            label: 'Invoicing',
                            icon: AttachMoneyIcon,
                          },
                          {
                            name: 'BOOKING_FORMS',
                            relPath: '/forms',
                            component: BookingDetail_Forms,
                            label: 'Forms',
                            icon: DescriptionOutlinedIcon,
                          },
                          {
                            name: 'BOOKING_REMINDER_TEMPLATES',
                            relPath: '/reminder-templates',
                            component: BookingDetail_ReminderTemplates,
                            label: 'Reminder Templates',
                            icon: EmailOutlined,
                          },
                        ],
                      },
                    ],
                  },
                  {
                    name: 'CALEVENT',
                    relPath: '/occurrence',
                    component: SwitchWithRoutes,
                    passthroughProps: true,
                    subroutes: [
                      {
                        name: 'CALEVENT_DETAIL',
                        relPath: '/:dateTime',
                        component: Calendar_CalEventDetail,
                        propsToPass: {
                          confirmAction,
                          activeCart,
                          currentLocation,
                          currentStaff,
                          rightDrawer,
                          putItemsInCart,
                          setRightDrawerView,
                          toggleRightDrawer,
                        },
                      },
                    ],
                  },
                  {
                    name: 'CALENDAR_DETAIL',
                    relPath: '',
                    component: Calendar_CalendarDetail,
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                      rightDrawer,
                      timeZone,
                      confirmAction,
                      setRightDrawerView,
                      toastDuration: TOAST_DURATION,
                      toggleRightDrawer,
                    },
                  },
                ],
              },
            ],
          },
          {
            name: 'CRM',
            relPath: '/crm',
            component: SwitchWithRoutes,
            permission: SECTIONS.CRM,
            subroutes: [
              {
                name: 'CHECKINS',
                relPath: '/checkins',
                component: SwitchWithRoutes,
                permission: SECTIONS.CRM,
                subroutes: [
                  {
                    name: 'CHECKIN_DETAIL',
                    relPath: '/:id',
                    component: CheckinDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'CHECKIN_LIST',
                    relPath: '',
                    component: CheckinList,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                ],
              },
              {
                name: 'CUSTOMERS',
                relPath: '/customers',
                component: SwitchWithRoutes,
                permission: SECTIONS.CRM,
                subroutes: [
                  {
                    name: 'CUSTOMER_NEW',
                    relPath: '/new',
                    component: CustomerNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'CUSTOMER_DETAIL',
                    relPath: '/:id',
                    component: CustomerDetail,
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                      defaultPlansView,
                      isAdHocChargeActive,
                      isPointsActive,
                      isStoreCreditActive,
                      rightDrawer,
                      timeZone,
                      confirmAction,
                      createNewCart,
                      focusOnCustomerCart,
                      setCheckinDialogCallback,
                      setRightDrawerView,
                      showCheckinDialogCustomer,
                      showCreateAdHocChargeDialog,
                      showNewGiftCardDialog,
                      toggleRightDrawer,
                      toastDuration: TOAST_DURATION,
                    },
                    subroutes: [
                      {
                        name: 'CUSTOMER_PROFILE',
                        relPath: '',
                        exact: true,
                        component: CustomerDetail_Profile,
                        label: 'Contact',
                        icon: PhoneOutlinedIcon,
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          timeZone,
                        },
                      },
                      {
                        name: 'CUSTOMER_NOTES',
                        relPath: '/notes',
                        component: CustomerDetail_Notes,
                        label: 'Notes',
                        icon: NoteIcon,
                        propsToPass: {
                          currentStaff,
                          timeZone,
                        },
                      },
                      // {
                      //   name: 'CUSTOMER_BOOKINGS',
                      //   relPath: '/bookings',
                      //   component: CustomerDetail_Bookings,
                      //   label: 'Bookings',
                      //   icon: CalendarTodayIcon,
                      //   propsToPass: {
                      //     currentLocation,
                      //     timeZone,
                      //   },
                      // },
                      {
                        name: 'CUSTOMER_CHECKIN_DETAIL',
                        relPath: '/checkins/:checkinId',
                        component: CustomerDetail_CheckinDetail,
                        hide: true,
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'CUSTOMER_CHECKINS',
                        relPath: '/checkins',
                        component: CustomerDetail_Checkins,
                        label: 'Checkins',
                        icon: CheckCircleOutlineIcon,
                        propsToPass: {
                          currentLocation,
                        },
                      },
                      {
                        name: 'CUSTOMER_HOUSEHOLD',
                        relPath: '/household',
                        component: CustomerDetail_Household,
                        label: 'Household',
                        icon: PeopleOutlineIcon,
                        propsToPass: {
                          confirmAction,
                          currentLocation,
                          showCheckinDialogCustomer,
                        },
                      },
                      {
                        label: 'Reservations',
                        subheader: true,
                        icon: PlaylistAddCheckIcon,
                      },
                      {
                        name: 'CUSTOMER_MY_RESERVATIONS',
                        relPath: '/my-reservations',
                        component: CustomerDetail_MyReservations,
                        label: 'My Reservations',
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          setRefundDialogCallback,
                          timeZone,
                          refundItem,
                          confirmAction,
                        },
                      },
                      {
                        name: 'CUSTOMER_HOUSEHOLD_RESERVATIONS',
                        relPath: '/household-reservations',
                        component: CustomerDetail_HouseholdReservations,
                        label: 'Household Reservations',
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          setRefundDialogCallback,
                          timeZone,
                          refundItem,
                          confirmAction,
                        },
                      },
                      {
                        name: 'CUSTOMER_CERTIFICATIONS',
                        relPath: '/certifications',
                        component: CustomerDetail_Certifications,
                        label: 'Certifications',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                          confirmAction,
                          routeComponent: PrivateRoute,
                        },
                      },
                      {
                        label: 'Notifications',
                        subheader: true,
                        icon: NotificationsIcon,
                      },
                      {
                        name: 'CUSTOMER_BILLING_NOTIFICATIONS',
                        relPath: '/billing-notifications',
                        component: CustomerDetail_BillingNotifications,
                        label: 'Billing',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'CUSTOMER_MARKETING_NOTIFICATIONS',
                        relPath: '/marketing-notifications',
                        component: CustomerDetail_MarketingNotifications,
                        label: 'Marketing',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        label: 'Plans',
                        subheader: true,
                        icon: ListAltIcon,
                      },
                      {
                        name: 'CUSTOMER_MEMBERSHIP',
                        relPath: '/memberships/:membershipId',
                        component: CustomerDetail_MembershipDetail,
                        hide: true,
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          rightDrawer,
                          timeZone,
                          putItemsInCustomerCart,
                          setRightDrawerView,
                          toggleRightDrawer,
                        },
                      },
                      {
                        name: 'CUSTOMER_PASS',
                        relPath: '/passes/:passId',
                        component: CustomerDetail_PassDetail,
                        hide: true,
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'CUSTOMER_TICKET',
                        relPath: '/tickets/:ticketId',
                        component: CustomerDetail_TicketDetail,
                        hide: true,
                        propsToPass: {
                          currentLocation,
                        },
                      },
                      {
                        name: 'CUSTOMER_MY_PLANS',
                        relPath: '/plans/mine',
                        component: CustomerDetail_MyPlans,
                        label: 'My Plans',
                        propsToPass: {
                          currentLocation,
                          defaultPlansView,
                        },
                      },
                      {
                        name: 'CUSTOMER_MANAGED_PLANS',
                        relPath: '/plans/managed',
                        component: CustomerDetail_ManagedPlans,
                        label: 'Managed Plans',
                        propsToPass: {
                          currentLocation,
                          defaultPlansView,
                        },
                      },
                      {
                        name: 'CUSTOMER_MY_GIFT_CARDS',
                        relPath: '/gift-cards/mine',
                        component: CustomerDetail_MyGiftCards,
                        label: 'My Gift Cards',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      ...(isStoreCreditActive ? [{
                        name: 'CUSTOMER_STORE_CREDIT',
                        relPath: '/store-credit',
                        component: CustomerDetail_StoreCredit,
                        label: 'Store Credit',
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          timeZone,
                          barcodeMinLength: BARCODE_MIN_LENGHT,
                        },
                      }] : []),
                      ...(isPointsActive ? [
                        {
                          name: 'CUSTOMER_POINTS_DETAIL',
                          relPath: '/points/:pointsLogId',
                          component: CustomerDetail_PointsDetail,
                          hide: true,
                          propsToPass: {
                            currentLocation,
                            timeZone,
                          },
                        },
                        {
                          name: 'CUSTOMER_POINTS',
                          relPath: '/points',
                          component: CustomerDetail_Points,
                          label: 'Loyalty Points',
                          propsToPass: {
                            currentLocation,
                            currentStaff,
                            timeZone,
                          },
                        },
                        {
                          name: 'CUSTOMER_REDEEM_POINTS',
                          relPath: '/redeem-points',
                          component: CustomerDetail_RedeemPoints,
                          label: 'Redeem Points',
                          hide: true,
                          propsToPass: {
                            currentLocation,
                            currentStaff,
                          },
                        },
                      ] : []),
                      {
                        name: 'CUSTOMER_PURCHASE_PLANS',
                        relPath: '/purchase-plans',
                        component: CustomerDetail_PurchasePlans,
                        hide: true,
                        propsToPass: {
                          currentLocation,
                          currentStaff,
                          paymentStatus,
                          timeZone,
                          isCartLoading,
                          rightDrawer,
                          putItemsInCustomerCart,
                          setRightDrawerView,
                          toggleRightDrawer,
                        },
                      },
                      {
                        label: 'Orders',
                        subheader: true,
                        icon: AttachMoneyIcon,
                        showForGuest: true,
                      },
                      {
                        name: 'CUSTOMER_ORDER',
                        relPath: '/orders/:orderId',
                        component: CustomerDetail_OrderDetail,
                        hide: true,
                        showForGuest: true,
                        propsToPass: {
                          checkoutOrderOnlyAtLocationOfPurchase,
                          currentLocation,
                          currentStaff,
                          paymentStatus,
                          timeZone,
                          cancelOrder,
                          confirmAction,
                          resetPaymentStatus,
                          setRefundDialogCallback,
                          showRefundOrderDialog,
                          cancellableItemTypes: CANCELLABLE_ITEM_TYPES,
                        },
                      },
                      {
                        name: 'CUSTOMER_ORDERS',
                        relPath: '/orders',
                        component: CustomerDetail_Orders,
                        label: 'Order History',
                        showForGuest: true,
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'CUSTOMER_PAYMENT_CARDS',
                        relPath: '/payment-cards',
                        component: CustomerDetail_PaymentCards,
                        label: 'Payment Cards',
                        propsToPass: {
                          confirmAction,
                          setAddCardDialogCallback,
                          showAddCardDialog,
                        },
                      },
                      {
                        name: 'CUSTOMER_PURCHASED_GIFT_CARDS',
                        relPath: '/gift-cards/purchased',
                        component: CustomerDetail_GiftCards,
                        label: 'Purchased Gift Cards',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                          showNewGiftCardDialog,
                        },
                      },
                      {
                        label: 'Documents',
                        subheader: true,
                        icon: FolderIcon,
                      },
                      {
                        name: 'CUSTOMER_WAIVERS',
                        relPath: '/waivers',
                        component: CustomerDetail_Waivers,
                        label: 'View Waivers',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'CUSTOMER_SUPPORT_DETAIL',
                        relPath: '/support/:supportId',
                        component: CustomerDetail_SupportDetail,
                        hide: true,
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'CUSTOMER_SUPPORT',
                        relPath: '/support',
                        component: CustomerDetail_Support,
                        label: 'Support',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'CUSTOMER_EMAIL_DEFAULT_WAIVER',
                        relPath: '/email-default-waiver',
                        component: CustomerDetail_EmailDefaultWaiver,
                        hide: true,
                        propsToPass: {
                          currentLocation,
                          toastDuration: TOAST_DURATION,
                        },
                      },
                      {
                        name: 'CUSTOMER_EMAIL_DOCUMENT',
                        relPath: '/email-document',
                        component: CustomerDetail_EmailDocument,
                        hide: true,
                        propsToPass: {
                          currentLocation,
                          toastDuration: TOAST_DURATION,
                        },
                      },
                    ],
                  },
                  {
                    name: 'CUSTOMER_LIST',
                    relPath: '',
                    component: CustomerList,
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                    },
                  },
                ],
              },
              {
                name: 'GROUPS',
                relPath: '/groups',
                component: SwitchWithRoutes,
                permission: SECTIONS.CRM,
                subroutes: [
                  {
                    name: 'GROUP_NEW',
                    relPath: '/new',
                    component: GroupNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'GROUP_DETAIL',
                    relPath: '/:id',
                    component: GroupDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                      confirmAction,
                    },
                  },
                  {
                    name: 'GROUP_LIST',
                    relPath: '',
                    component: GroupList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'MERGE_ACCOUNTS',
                relPath: '/merge-accounts',
                component: MergeAccounts,
                permission: SECTIONS.CRM,
                propsToPass: {
                  confirmAction,
                  currentLocation,
                  isPointsActive,
                },
              },
            ],
          },
          {
            name: 'FINANCES',
            relPath: '/finances',
            component: SwitchWithRoutes,
            permission: SECTIONS.FINANCES,
            subroutes: [
              {
                name: 'TRANSACTIONS',
                relPath: '/transactions',
                component: SwitchWithRoutes,
                permission: SECTIONS.FINANCES,
                subroutes: [
                  {
                    name: 'TRANSACTION_DETAIL',
                    relPath: '/:id',
                    component: TransactionDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'TRANSACTION_LIST',
                    relPath: '',
                    component: TransactionList,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                ],
              },
              {
                name: 'MEMBERSHIPS',
                relPath: '/memberships',
                component: SwitchWithRoutes,
                permission: SECTIONS.FINANCES,
                subroutes: [
                  {
                    name: 'MEMBERSHIP_DETAIL',
                    relPath: '/:id',
                    component: MembershipDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'MEMBERSHIP_LIST',
                    relPath: '',
                    component: MembershipList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'REVENUE_CATEGORY_REPORTS',
                relPath: '/revenue-category-reports',
                component: SwitchWithRoutes,
                permission: SECTIONS.FINANCES,
                subroutes: [
                  {
                    name: 'REVENUE_CATEGORY_REPORT_NEW',
                    relPath: '/new',
                    component: RevenueCategoryReportNew,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                  {
                    name: 'REVENUE_CATEGORY_REPORT_DETAIL',
                    relPath: '/:id',
                    component: RevenueCategoryReportDetail,
                    subroutes: [
                      {
                        name: 'REVENUE_CATEGORY_REPORT_REPORT',
                        relPath: '',
                        exact: true,
                        component: RevenueCategoryReportDetail_Report,
                        label: 'Report',
                        propsToPass: {
                          currentLocation,
                          reportHeaders: REPORT_HEADERS,
                        },
                      },
                      {
                        name: 'REVENUE_CATEGORY_REPORT_INFO',
                        relPath: '/info',
                        component: RevenueCategoryReportDetail_Info,
                        label: 'Info',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                          confirmAction,
                        },
                      },
                      {
                        name: 'REVENUE_CATEGORY_REPORT_QUICKBOOKS',
                        relPath: '/quickbooks',
                        component: RevenueCategoryReportDetail_Quickbooks,
                        label: 'Quickbooks',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                          confirmAction,
                        },
                      },
                    ],
                    propsToPass: {
                      currentLocation,
                    },
                  },
                  {
                    name: 'REVENUE_CATEGORY_REPORT_LIST',
                    relPath: '',
                    component: RevenueCategoryReportList,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                ],
              },
              {
                name: 'QUICKBOOKS_CONNECTIONS',
                relPath: '/quickbooks-connections',
                component: SwitchWithRoutes,
                permission: SECTIONS.FINANCES,
                subroutes: [
                  {
                    name: 'QUICKBOOKS_CONNECTION_NEW',
                    relPath: '/new',
                    component: QuickbooksConnectionNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'QUICKBOOKS_CONNECTION_DETAIL',
                    relPath: '/:id',
                    component: QuickbooksConnectionDetail,
                    subroutes: [
                      {
                        name: 'QUICKBOOKS_CONNECTION_INFO',
                        relPath: '',
                        exact: true,
                        component: QuickbooksConnectionDetail_Info,
                        label: 'Info',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                          confirmAction,
                        },
                      },
                      {
                        name: 'QUICKBOOKS_CONNECTION_CONNECTION',
                        relPath: '/connection',
                        component: QuickbooksConnectionDetail_Connection,
                        label: 'Connection',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'QUICKBOOKS_CONNECTION_CLASSES',
                        relPath: '/classes',
                        component: QuickbooksConnectionDetail_Classes,
                        label: 'Classes',
                      },
                      {
                        name: 'QUICKBOOKS_CONNECTION_MAPPING',
                        relPath: '/mapping',
                        exact: true,
                        component: QuickbooksConnectionDetail_Mapping,
                        label: 'Mapping',
                      },
                      {
                        name: 'QUICKBOOKS_CONNECTION_MAPPING_EDIT',
                        relPath: '/mapping/edit',
                        component: QuickbooksConnectionDetail_MappingEdit,
                        hide: true,
                        fullScreen: true,
                        propsToPass: {
                          confirmAction,
                        },
                      },
                      {
                        name: 'QUICKBOOKS_CONNECTION_LEDGERS',
                        relPath: '/ledgers',
                        component: QuickbooksConnectionDetail_LedgerList,
                        label: 'Ledger List',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                          confirmAction,
                        },
                      },
                    ],
                  },
                  {
                    name: 'QUICKBOOKS_CONNECTION_LIST',
                    relPath: '',
                    component: QuickbooksConnectionList,
                  },
                ],
              },
              {
                name: 'ACH_TRANSACTIONS',
                relPath: '/ach-transactions',
                component: AchTransactions,
                permission: SECTIONS.FINANCES,
                propsToPass: {
                  currentLocation,
                  timeZone,
                },
              },
            ],
          },
          {
            name: 'FORMS',
            relPath: '/forms',
            component: SwitchWithRoutes,
            permission: SECTIONS.FORMS,
            propsForAll: {
              breadcrumb1: 'Forms',
            },
            subroutes: [
              {
                name: 'QUESTIONS',
                relPath: '/questions',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.FORMS,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Questions',
                },
                subroutes: [
                  {
                    name: 'QUESTION_NEW',
                    relPath: '/new',
                    component: QuestionNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'QUESTION_DETAIL',
                    relPath: '/:id',
                    component: QuestionDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'QUESTION_LIST',
                    relPath: '',
                    component: QuestionList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'SUBMISSIONS',
                relPath: '/submissions',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.FORMS,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Submissions',
                },
                subroutes: [
                  {
                    name: 'SUBMISSION_PREVIEW',
                    relPath: '/preview/:previewId',
                    component: SubmissionPreview,
                  },
                  {
                    name: 'SUBMISSION_DETAIL',
                    relPath: '/:id',
                    component: SubmissionDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'SUBMISSION_LIST',
                    relPath: '',
                    component: SubmissionList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'TEMPLATES',
                relPath: '/templates',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.FORMS,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Templates',
                },
                subroutes: [
                  {
                    name: 'TEMPLATE_NEW',
                    relPath: '/new',
                    component: TemplateNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'TEMPLATE_DETAIL',
                    relPath: '/:id',
                    component: TemplateDetail,
                    subroutes: [
                      {
                        name: 'TEMPLATE_INFO',
                        relPath: '',
                        exact: true,
                        component: TemplateDetail_Info,
                        label: 'Info',
                        propsToPass: {
                          currentLocation,
                          timeZone,
                        },
                      },
                      {
                        name: 'TEMPLATE_FORM_CONTENT',
                        relPath: '/content',
                        component: TemplateDetail_FormContent,
                        label: 'Form Content',
                        propsToPass: {
                          currentLocation,
                        },
                      },
                    ],
                  },
                  {
                    name: 'TEMPLATE_LIST',
                    relPath: '',
                    component: TemplateList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
            ],
          },
          {
            name: 'MERCHANT_TRACK',
            relPath: '/merchant-track',
            component: MerchantTrack,
            permission: SECTIONS.MERCHANT_TRACK,
            propsToPass: {
              currentLocation,
            },
          },
          {
            name: 'POINTS',
            relPath: '/points',
            component: SwitchWithRoutes,
            permission: SECTIONS.POINTS,
            propsForAll: {
              breadcrumb1: 'Loyalty Points',
            },
            subroutes: [
              {
                name: 'POINTS_REDEMPTIONS',
                relPath: '/redemptions',
                component: Points_RedemptionList,
                permission: SECTIONS.POINTS,
                propsToPass: {
                  currentLocation,
                  timeZone,
                },
              },
              {
                name: 'POINTS_STAFF_ADJUSTMENTS',
                relPath: '/staff-adjustments',
                component: Points_StaffAdjustmentList,
                permission: SECTIONS.POINTS,
                propsToPass: {
                  currentLocation,
                  timeZone,
                },
              },
            ],
          },
          {
            name: 'STORE',
            relPath: '/store',
            component: SwitchWithRoutes,
            permission: SECTIONS.STORE,
            propsForAll: {
              breadcrumb1: 'Store',
            },
            subroutes: [
              {
                name: 'SHOP',
                relPath: '/shop',
                component: Shop,
                permission: SECTIONS.STORE,
                subroutes: [
                  {
                    name: 'SHOP_SCANNER',
                    relPath: '/scanner',
                    component: Shop_Scanner,
                    label: 'Scanner',
                    icon: Barcode,
                    propsToPass: {
                      activeCartCustomer,
                      currentLocation,
                      rightDrawer,
                      putItemsInCart,
                      setRightDrawerView,
                      toggleRightDrawer,
                      barcodeMinLength: BARCODE_MIN_LENGHT,
                    },
                  },
                  {
                    name: 'SHOP_PRODUCTS',
                    relPath: '',
                    component: Shop_Products,
                    label: 'Shop',
                    icon: ShoppingCartIcon,
                    propsToPass: {
                      activeCart,
                      currentLocation,
                      currentStaff,
                      isAdHocChargeActive,
                      rightDrawer,
                      setRightDrawerView,
                      putItemsInCart,
                      showCreateAdHocChargeDialog,
                      toggleRightDrawer,
                    },
                  },
                ],
              },
              {
                name: 'ORDERS',
                relPath: '/orders',
                component: Orders,
                permission: SECTIONS.STORE,
                propsToPass: {
                  currentLocation,
                  timeZone,
                },
              },
              {
                name: 'GIFT_CARDS',
                relPath: '/gift-cards',
                component: SwitchWithRoutesAndLink2,
                permission: SECTIONS.STORE,
                passthroughProps: true,
                propsForAll: {
                  breadcrumb2: 'Gift Cards',
                },
                subroutes: [
                  {
                    name: 'GIFT_CARD_DETAIL',
                    relPath: '/:id',
                    component: GiftCardDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'GIFT_CARD_LIST',
                    relPath: '',
                    component: GiftCardList,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                      showNewGiftCardDialog,
                    },
                  },
                ],
              },
              {
                name: 'CASH_DRAWER',
                relPath: '/cash-drawer',
                component: CashDrawer,
                permission: SECTIONS.STORE,
                subroutes: [
                  {
                    label: 'Cash Drawer',
                    subheader: true,
                    icon: AttachMoneyIcon,
                  },
                  {
                    name: 'CASH_DRAWER_DETAIL',
                    relPath: '/history/:drawerId',
                    component: DrawerDetail,
                    hide: true,
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                      timeZone,
                    },
                  },
                  {
                    name: 'CASH_DRAWER_HISTORY',
                    relPath: '/history',
                    component: DrawerHistory,
                    label: 'Drawer History',
                    propsToPass: {
                      currentLocation,
                    },
                  },
                  {
                    name: 'CASH_DRAWER_CURRENT',
                    relPath: '',
                    component: CurrentDrawer,
                    label: 'Current Drawer',
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                      timeZone,
                    },
                  },
                ],
              },
            ],
          },
          {
            name: 'SUPPORT',
            relPath: '/support',
            component: SwitchWithRoutes,
            permission: SECTIONS.SUPPORT,
            subroutes: [
              {
                name: 'SUPPORT_HISTORY',
                relPath: '/history',
                component: SwitchWithRoutes,
                permission: SECTIONS.SUPPORT,
                subroutes: [
                  {
                    name: 'SUPPORT_HISTORY_DETAIL',
                    relPath: '/:id',
                    component: Support_HistoryDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'SUPPORT_HISTORY_LIST',
                    relPath: '',
                    component: Support_HistoryList,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                ],
              },
              {
                name: 'SUPPORT_ISSUE_TYPES',
                relPath: '/issue-types',
                component: SwitchWithRoutes,
                permission: SECTIONS.SUPPORT,
                subroutes: [
                  {
                    name: 'SUPPORT_ISSUE_TYPE_NEW',
                    relPath: '/new',
                    component: Support_IssueTypeNew,
                    propsToPass: {
                      areRevenueCategoriesRequired,
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'SUPPORT_ISSUE_TYPE_DETAIL',
                    relPath: '/:id',
                    component: Support_IssueTypeDetail,
                    propsToPass: {
                      currentLocation,
                      timeZone,
                    },
                  },
                  {
                    name: 'SUPPORT_ISSUE_TYPE_LIST',
                    relPath: '',
                    component: Support_IssueTypeList,
                    propsToPass: {
                      currentLocation,
                    },
                  },
                ],
              },
              {
                name: 'SUPPORT_REQUESTS',
                relPath: '/requests',
                component: SwitchWithRoutes,
                permission: SECTIONS.SUPPORT,
                subroutes: [
                  {
                    name: 'SUPPORT_REQUEST_LIST',
                    relPath: '',
                    component: Support_Requests,
                    propsToPass: {
                      currentLocation,
                      currentStaff,
                      confirmAction,
                      timeZone,
                    },
                    subroutes: [
                      {
                        name: 'SUPPORT_REQUEST_DETAIL',
                        relPath: '/:id',
                        component: Support_Request_Detail,
                        propsToPass: {
                          currentStaff,
                          confirmAction,
                          timeZone,
                          skeletonAnimation: SKELETON_ANIMATION,
                        },
                      },
                    ],
                  },
                ],
              },
            ],
          },
          {
            name: 'HOME',
            relPath: '/',
            component: Dashboard,
            propsToPass: {
              currentLocation,
              currentStaff,
            },
          },
        ],
      },
    ],
  },
]);
/* eslint-enable camelcase */

// eslint-disable-next-line import/prefer-default-export
export const generateRouteConfig = (props) => {
  const routeConfig = getRouteConfigFromProps(props);
  injectConfig(routeConfig);

  return routeConfig;
};
